import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          StakeLab is a professional validator for Solana based in Canada and New Zealand.
        </p>
        <div className="contactDiv">
          Contact us at <a className="contactLink" href="mailto:hello@stakelab.org">hello@stakelab.org</a> with any comments/questions.
        </div>
      </header>
    </div>
  );
}

export default App;
